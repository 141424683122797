<template>
  <onboarding-scaffold>
    <template v-slot:content>
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <div class="w-full flex items-center justify-center">
        <div
          class="
            xl:w-1/2
            lg:w-full
            md:w-full
            w-full
            min-h-screen
            py-14
            xl:px-28
            lg:px-0
            md:px-0
            px-0
          "
        >
          <form-scaffold
            :loading="submitting"
            button-text="Reset Password"
            @sendAction="send"
          >
            <span slot="heading-text">Almost Done</span>
            <div slot="field-instructions">
              You need to secure your account by setting up a strong password
              for your account.
            </div>
            <div slot="input-fields">
              <div class="relative mt-6">
                <label
                  class="
                    text-legend-gray
                    dark:text-border-color
                    font-semibold
                    block
                    leading-relaxed
                  "
                  >Password</label
                >
                <input
                  v-model.trim="$v.password.$model"
                  :type="inputType"
                  placeholder="e.g legend@#20"
                  class="
                    text-seconadry-border
                    border border-border-color
                    bg-transparent
                    rounded-lg
                    w-full
                    p-3
                    focus:outline-none
                  "
                />
                <img
                  :src="imageURL"
                  alt="toggle password visibility"
                  class="absolute right-6 top-11 w-6 cursor-pointer"
                  @click="togglePasswordField"
                />
                <ValidationMessage v-if="loading && !$v.password.required">
                  <span slot="message">Password is required.</span>
                </ValidationMessage>
                <ValidationMessage v-if="loading && !$v.password.minLength">
                  <span slot="message"
                    >Password must be atleast 8 characters</span
                  >
                </ValidationMessage>
              </div>
              <div class="text-sm my-2">
                <span class="text-primary-color font-extrabold mr-2"
                  >Note:</span
                >
                <span class="text-legend-gray dark:text-border-color"
                  >Make Sure your password is upto 8 charaters long. For a
                  stronger password you can use
                  <strong>uppercase/lowercase/numbers</strong> &
                  <strong>symbols</strong>
                </span>
              </div>
              <div class="relative mt-6">
                <label
                  class="
                    text-legend-gray
                    dark:text-border-color
                    font-semibold
                    block
                    leading-relaxed
                  "
                  >Confirm Password</label
                >
                <input
                  v-model.trim="$v.confirm_password.$model"
                  :type="inputType"
                  placeholder="e.g legend@#20"
                  class="
                    text-seconadry-border
                    border border-border-color
                    bg-transparent
                    rounded-lg
                    w-full
                    p-3
                    focus:outline-none
                  "
                />
                <img
                  :src="imageURL"
                  alt="toggle password visibility"
                  class="absolute right-6 top-11 w-6 cursor-pointer"
                  @click="togglePasswordField"
                />
                <ValidationMessage
                  v-if="loading && !$v.confirm_password.sameAsPassword"
                >
                  <span slot="message">Passwords do not match.</span>
                </ValidationMessage>
              </div>
            </div>
          </form-scaffold>
        </div>
      </div>
    </template>
  </onboarding-scaffold>
</template>

<script>
import OnboardingScaffold from '@/components/OnboardingScaffold.vue'
import FormScaffold from '@/components/FormScaffold.vue'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'CreatePassword',
  components: {
    OnboardingScaffold,
    FormScaffold,
  },
  computed: {
    ...mapGetters(['username']),
    imageURL() {
      let image

      this.inputType === 'password'
        ? (image = 'eye-icon.svg')
        : (image = 'eye-icon-active.svg')

      return this.getImgUrl(image)
    },
  },
  data() {
    return {
      password: null,
      confirm_password: null,
      inputType: 'password',
      loading: false,
      submitting: false,
      notification: null,
    }
  },
  validations() {
    return {
      password: { required, minLength: minLength(8) },
      confirm_password: {
        sameAsPassword: sameAs('password'),
      },
    }
  },
  methods: {
    getImgUrl(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
    togglePasswordField() {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    async send() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        const { status } = await this.axios.put('/register/password/store', {
          username: this.username,
          password: this.password,
          password_confirmation: this.confirm_password,
        })

        if (status >= 200 && status < 300) {
          this.$router.push({ name: 'Login' })
        }
      } catch (error) {
        let errorMessage

        if (error.response.status === 422) {
          errorMessage = error.response.data.errors.username[0]
        } else if (error.response.status === 400) {
          errorMessage = error.response.data.message
        }

        this.notification = {
          type: 'error',
          message: errorMessage,
        }
      }
    },
  },
}
</script>
